/**
 * boeppel
 */
.boeppel {
    position: absolute;

    top: -6px;
    right: -4px;

    width: 18px;
    height: 18px;

    border: 3px solid white;
    border-radius: 10px;

    background: $accent-yellow;
    z-index: 1000;
}

.boeppel-big {
    position: absolute;

    top: 21px;
    right: 52px;

    width: 25px;
    height: 25px;

    border: 1px solid $accent-yellow;
    border-radius: 50%;

    background: $accent-yellow;
}

.boeppel-list {
    position: absolute;

    top: -6px;
    right: 8px;

    width: 16px;
    height: 16px;

    border: 2px solid white;
    border-radius: 10px;

    background: $accent-yellow;
}

