@import "fonts";

/**
 * LIVE
 */
.column-callout {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 6px;

    background: white;

    border-top: 2px solid #3295A5;
    border-radius: 2px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);

    > .header {
        padding-top: 6px;
        background: white;

        text-align: start;
        @include eon-bold;
        font-size: 12px;
        color: black;
    }

    > .body {
        background: white;

        text-align: center;
        @include eon-black;
        font-size: 16px;
        color: black;
    }
}

/**
 * CONSUMPTION ALERT
 */
.consumption-alert-callout {
    padding: 5px;
    padding-right: 15px;
    background: #b00402;
    color: white;
    @include eon-black;

    &.positive {
        background: #0097A7;
    }

    &.negative {
        background: #b00402
    }
}

/**
 * APPLIANCE
 */
.appliance-callout {
    pointer-events: none;

    .appliance-callout-icon {
        position: relative;
        width: 48px;
        height: 48px;

        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;

        -webkit-mask-size: cover;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
    }
}

