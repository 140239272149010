.button-wla {
    height: 55px;
    display: block;

    background: #F59B00;
    color: #fff;

    margin: 50px auto 20px;
    padding: 13px 10px;

    font-size: 22px;
    text-decoration: none;
    text-align: center; /*line-height: 55px; */

    border: 0 !important;
    border-radius: 27px;
    -webkit-border-radius: 27px;
    -moz-border-radius: 27px;

    &.reg {
        width: 240px;
    }
}

.override-btn {
    margin: 0;
    margin-bottom: 15px;
    min-width: 360px;
}

.override-btn2 {
    margin: 0;
    margin-bottom: 15px;
    min-width: 240px;
}

.btn-scale {
    height: 55px;
    width: 100%;
    display: block;

    background: #F59B00;
    color: #fff;

    padding: 13px 10px;

    font-size: 22px;
    text-decoration: none;
    text-align: center; /*line-height: 55px; */

    border: 0 !important;
    border-radius: 27px;
    -webkit-border-radius: 27px;
    -moz-border-radius: 27px;
}


.btn-round-edit {
    width: 45px;
    height: 45px;

    border: 0;
    border-radius: calc(45px / 2);

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #333333;

    .btn-edit-icon {
        width: 100%;
        height: 100%;
        background-image: url(/assets/images/ui-icons/edit/editTransparent@3x.png);
        background-size: contain;
    }
}



.button-reset {
    border: none;
    background: 0;

    &:hover {
        outline: none;
    }
}
