$padding-t : 4px;
$padding-s : 8px;
$padding-m : 16px;
$padding-l : 24px;
$padding-x : 36px;

$margin-t : 4px;
$margin-s : 8px;
$margin-m : 16px;
$margin-l : 24px;
$margin-x : 36px;

/**
 * PADDING
 */
.p-a-n { padding : 0;}
.p-a-t { padding: $padding-t !important;}
.p-a-s { padding: $padding-s !important;}
.p-a-m { padding: $padding-m !important;}
.p-a-l { padding: $padding-l !important;}
.p-a-x { padding: $padding-x !important;}

.p-h-n { padding-left: 0 !important; padding-right: 0 !important; }
.p-h-t { padding-left: $padding-t !important; padding-right: $padding-t !important; }
.p-h-s { padding-left: $padding-s !important; padding-right: $padding-s !important; }
.p-h-m { padding-left: $padding-m !important; padding-right: $padding-m !important; }
.p-h-l { padding-left: $padding-l !important; padding-right: $padding-l !important; }
.p-h-x { padding-left: $padding-x !important; padding-right: $padding-x !important; }

.p-v-n { padding-top: 0 !important; padding-bottom: 0!important;}
.p-v-t { padding-top: $padding-t !important; padding-bottom: $padding-t !important; ; }
.p-v-s { padding-top: $padding-s !important; padding-bottom: $padding-s !important; ; }
.p-v-m { padding-top: $padding-m !important; padding-bottom: $padding-m !important; ; }
.p-v-l { padding-top: $padding-l !important; padding-bottom: $padding-l !important; ; }
.p-v-x { padding-top: $padding-x !important; padding-bottom: $padding-x !important; ; }

.p-l-n { paddin-left: 0!important;}
.p-r-n { paddin-right: 0!important; }
.p-t-n { paddin-top: 0!important; }
.p-b-n { paddin-bottom: 0!important; }

/// directions
.p-l-t { padding-left: $padding-t!important; }
.p-r-t { padding-right: $padding-t!important; }
.p-t-t { padding-top: $padding-t!important; }
.p-b-t { padding-bottom: $padding-t!important; }

.p-l-s { padding-left: $padding-s!important; }
.p-r-s { padding-right: $padding-s!important; }
.p-t-s { padding-top: $padding-s!important; }
.p-b-s { padding-bottom: $padding-s!important; }

.p-l-m { padding-left: $padding-m !important; }
.p-r-m { padding-right: $padding-m !important; }
.p-t-m { padding-top: $padding-m !important; }
.p-b-m { padding-bottom: $padding-m !important; }

.p-l-l { padding-left: $padding-l !important; }
.p-r-l { padding-right: $padding-l !important; }
.p-t-l { padding-top: $padding-l !important; }
.p-b-l { padding-bottom: $padding-l !important; }

.p-l-x { padding-left: $padding-x !important; }
.p-r-x { padding-right: $padding-x !important; }
.p-t-x { padding-top: $padding-x !important; }
.p-b-x { padding-bottom: $padding-x !important; }

/**
 * MARGIN
 */
.m-a-n { margin : 0 !important;}
.m-a-t { margin: $margin-t !important;}
.m-a-s { margin: $margin-s !important;}
.m-a-m { margin: $margin-m !important;}
.m-a-l { margin: $margin-l !important;}
.m-a-x { margin: $margin-x !important;}

.m-h-n { margin-left: 0 !important; margin-right: 0!important; }
.m-h-t { margin-left: $margin-t !important; margin-right: $margin-t !important; }
.m-h-s { margin-left: $margin-s !important; margin-right: $margin-s !important; }
.m-h-m { margin-left: $margin-m !important; margin-right: $margin-m !important; }
.m-h-l { margin-left: $margin-l !important; margin-right: $margin-l !important; }
.m-h-x { margin-left: $margin-x !important; margin-right: $margin-x !important; }

.m-v-n { margin-top: 0 !important; margin-bottom: 0 !important;}
.m-v-t { margin-top: $margin-t !important; margin-bottom: $margin-t !important; }
.m-v-s { margin-top: $margin-s !important; margin-bottom: $margin-s !important; }
.m-v-m { margin-top: $margin-m !important; margin-bottom: $margin-m !important; }
.m-v-l { margin-top: $margin-l !important; margin-bottom: $margin-l !important; }
.m-v-x { margin-top: $margin-x !important; margin-bottom: $margin-x !important; }

// single directions
.m-l-n { margin-left: 0 !important; }
.m-r-n { margin-right: 0 !important; }
.m-t-n { margin-top: 0 !important; }
.m-b-n { margin-bottom: 0 !important; }

.m-l-t { margin-left: $margin-t !important; }
.m-r-t { margin-right: $margin-t !important; }
.m-t-t { margin-top: $margin-t !important; }
.m-b-t { margin-bottom: $margin-t !important; }

.m-l-s { margin-left: $margin-s !important; }
.m-r-s { margin-right: $margin-s !important; }
.m-t-s { margin-top: $margin-s !important; }
.m-b-s { margin-bottom: $margin-s !important; }

.m-l-m { margin-left: $margin-m !important; }
.m-r-m { margin-right: $margin-m !important; }
.m-t-m { margin-top: $margin-m !important; }
.m-b-m { margin-bottom: $margin-m !important; }

.m-l-l { margin-left: $margin-l !important; }
.m-r-l { margin-right: $margin-l !important; }
.m-t-l { margin-top: $margin-l !important; }
.m-b-l { margin-bottom: $margin-l !important; }

.m-l-x { margin-left: $margin-x !important; }
.m-r-x { margin-right: $margin-x !important; }
.m-t-x { margin-top: $margin-x !important; }
.m-b-x { margin-bottom: $margin-x !important; }
