fieldset.eon-input {
    position: relative;
    height: 56px;

    border: 2px solid #8F9192;
    border-radius: 4px;

    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0 10px;
}

fieldset.eon-input:focus-within {
    border-color: #519fb1;
}

fieldset.eon-input > legend {
    font-size: 14px;
    padding-left: 3px;
    padding-right: 3px;
    font-family: EONBrixSans, sans-serif;
    color: #39393a;
}

.eon-input > input {
    position: absolute;
    top: 7px;
    width: calc(100% - 20px);
    border: none;
    font-size: 18px;
    font-family: EONBrixSans, sans-serif;
    color: #39393a;
}

.eon-input > i {
    position: absolute;
    right: 10px;
    top: 3px;
    cursor: pointer;
}
