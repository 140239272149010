@import "../fonts";

.tile-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 20px 30px;
    position: relative
}

.tile-view > .tile-wrapper {
    width: 33.3%;
}

.tile-container {
    padding: 20px;
    width: 100%;
    height: 100%;
}

.tile {
    position: relative;
    width: 100%;
    height: 100%;

    padding: 20px;
    border-radius: 8px;

    background: #FFFFFF;

    cursor: pointer;
}

.tile-add {
    cursor: pointer;
}

.tile-close-control {
    display: inline-block;
    position: absolute;

    top: -16px;
    right: -16px;
    z-index: 10000;
}

.tile-inner {
    width: inherit;
    height: inherit;

    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.tile-header-row {
    display: flex;
    flex-direction: row;
}

.tile-header-row > h3 {
    flex: 1;
}

h3.tile-heading {
    font-size: 32px;
    color: #39393a;
    @include eon-bold;
}

.tile-heading {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.tile-heading > h3 {
    flex: 1 0;
    font-size: 32px;
    color: #39393a;
    @include eon-bold;
    margin-bottom: 0;
}

.tile-close-control {
    position: absolute;
    top: 20px;
    right: 20px;
}

.tile-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    position: relative;
}


.tile-chart-abs {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}
