@import "../layout";
@import "../type";

$eon-red: #ea1b0a;
$eon-button-hover-red: #b9280a;

$eon-turquoise: #1ea2b1;
$eon-button-hover-turquoise: #2b91a3;


$fs-button: 18px;
$button-height: 48px;
$button-height-sm: 34px;

$button-edge-width: 24px;
$button-edge-width-sm: 17px;


@mixin border-edge($url) {
    mask-image: url("#{$url}");
    mask-repeat: no-repeat;
    mask-size: contain;
}

@mixin hover-bg-transition {
    transition: background-color 100ms ease;
}

@mixin entire-button-hover-effect($primary-color: #{$eon-red}, $hover-color: #{$eon-button-hover-red}) {
    @include hover-bg-transition;

    background-color: $primary-color;

    &:before {
        display: block;
        position: absolute;
        top: 0;
        left: -$button-edge-width;
        height: $button-height;
        width: $button-edge-width;

        @include border-edge("~src/assets/img/shapes/eon-button-shapes/left.svg");
        @include hover-bg-transition;

        content: " ";

        background-color: $primary-color;
    }
    &:after {
        display: block;
        position: absolute;
        top: 0;
        right: -$button-edge-width;
        height: $button-height;
        width: $button-edge-width;

        @include border-edge("~src/assets/img/shapes/eon-button-shapes/right.svg");
        @include hover-bg-transition;

        content: " ";
        background-color: $primary-color;
    }
    &:hover {
        background-color: $hover-color;
        &:before {
            background-color: $hover-color;
        }

        &:after {
            background-color: $hover-color;
        }
    }
}

@mixin eon-button {
    position: relative;
    height: $button-height;

    padding-left: $button-edge-width;
    padding-right: $button-edge-width;

    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: $button-edge-width;
    margin-right: $button-edge-width;

    @include row(center, center);
    display: inline-flex;

    @include eon-regular;
    font-size: $fs-button;
    color: white;
    border: 0;

    cursor: pointer;

    @include entire-button-hover-effect($eon-red, $eon-button-hover-red);
}

button.eon-button-new {
    @include eon-button;
}

button.eon-icon-button {
    @include eon-button;
    padding-left: 0;
    padding-right: 0;
}

button.eon-button-new.turquoise {
    @include entire-button-hover-effect($eon-turquoise, $eon-button-hover-turquoise);
}

a.eon-button-new {
    @include eon-button;
    text-decoration: none;
    color: white;
}

a.button:visited {
    color: white;
}

// SMALL
// ================================================================================
button.eon-button-new.small {
    height: $button-height-sm;
    padding-left: $button-edge-width-sm;
    padding-right: $button-edge-width-sm;
    margin-left: $button-edge-width-sm;
    margin-right: $button-edge-width-sm;
}

button.eon-button-new.small:before {
    left: -$button-edge-width-sm;
    height: $button-height-sm;
    width: $button-edge-width-sm;
}

button.eon-button-new.small:after {
    right: -$button-edge-width-sm;
    height: $button-height-sm;
    width: $button-edge-width-sm;
}
