/**
 * DEFINITIONS
 */
$accent-yellow: rgb(255, 195, 0);
$indigo: rgb(0, 170, 225);
$indigo-dark: rgb(20, 60, 140);
$red-muted: #b9280a;
$red-banner: #fac6c2;


/**
 * CLASSES
 */
.yellow {
    color: $accent-yellow;
}

.indigo-clr {
    color: $indigo;
}

.indigo-dark-clr {
    color: $indigo-dark;
}

.yellow-bg {
    background: $accent-yellow;
}

.white-svg {
    svg {
        path {
            fill: #FFFFFF;
        }

        width: 150px;
        height: 150px;

        @media screen and (max-width: 480px) {
            width: 100px;
            height: 100px;
        }
    }
}


/**
 * E.ON
 */
$eon-turquoise: #1ea2b1;

.turquoise {
    background: $eon-turquoise !important;
}
