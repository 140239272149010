.card {
    width: 100%;
    height: 100%;
    padding: 25px 30px;
    background: #FFFFFF;

    h3 {
        font-size: 30px;
        font-family: "Innogy regular", sans-serif;
        font-weight: normal;
    }

    p{
        line-height: 24pt;
    }
}

@media screen and (max-width: 1400px) {
    .card {
        padding: 35px 40px;
    }
}

