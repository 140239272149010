$square-button-width: 32px;

$square-button-bg-turquoise: #1ea2b1;
$square-button-bg-turquoise-hover: #2b91a3;

$square-button-bg-red: #ea1b0a;
$square-button-bg-red-hover: #d51607;

button.icon-only {
    width: $square-button-width;
    height: $square-button-width;

    mask-image: url("~src/assets/img/graphics/button-shape/button-shape.svg ");
    mask-size: contain;
    mask-position: center;

    border: none;

    display: flex;flex-direction: row;
    justify-content: center;
    align-items: center;
;
}


button.icon-only.turqouise {
    background-color: $square-button-bg-turquoise;
    transition: background-color 100ms ease;

    &:hover {
        background-color: $square-button-bg-turquoise-hover;
    }
}
