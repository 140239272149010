@import '~@angular/cdk/overlay-prebuilt.css';
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/**
 * DEFINITIONS
 */
/**
 * CLASSES
 */
.yellow {
  color: #ffc300;
}

.indigo-clr {
  color: #00aae1;
}

.indigo-dark-clr {
  color: #143c8c;
}

.yellow-bg {
  background: #ffc300;
}

.white-svg svg {
  width: 150px;
  height: 150px;
}
.white-svg svg path {
  fill: #FFFFFF;
}
@media screen and (max-width: 480px) {
  .white-svg svg {
    width: 100px;
    height: 100px;
  }
}

/**
 * E.ON
 */
.turquoise {
  background: #1ea2b1 !important;
}

.icn-btn-close {
  font-family: "Innogy Icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 25px;
  color: #F59B00;
  border: 0;
  background: 0;
}
.icn-btn-close:hover {
  color: #333333;
}
.icn-btn-close:focus {
  border: 0;
  background: 0;
  outline: 0;
}
.icn-btn-close:before {
  content: "j";
}

/**
 * PADDING
 */
.p-a-n {
  padding: 0;
}

.p-a-t {
  padding: 4px !important;
}

.p-a-s {
  padding: 8px !important;
}

.p-a-m {
  padding: 16px !important;
}

.p-a-l {
  padding: 24px !important;
}

.p-a-x {
  padding: 36px !important;
}

.p-h-n {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-h-t {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-h-s {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-h-m {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-h-l {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-h-x {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.p-v-n {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-v-t {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-v-s {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-v-m {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-v-l {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-v-x {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.p-l-n {
  paddin-left: 0 !important;
}

.p-r-n {
  paddin-right: 0 !important;
}

.p-t-n {
  paddin-top: 0 !important;
}

.p-b-n {
  paddin-bottom: 0 !important;
}

.p-l-t {
  padding-left: 4px !important;
}

.p-r-t {
  padding-right: 4px !important;
}

.p-t-t {
  padding-top: 4px !important;
}

.p-b-t {
  padding-bottom: 4px !important;
}

.p-l-s {
  padding-left: 8px !important;
}

.p-r-s {
  padding-right: 8px !important;
}

.p-t-s {
  padding-top: 8px !important;
}

.p-b-s {
  padding-bottom: 8px !important;
}

.p-l-m {
  padding-left: 16px !important;
}

.p-r-m {
  padding-right: 16px !important;
}

.p-t-m {
  padding-top: 16px !important;
}

.p-b-m {
  padding-bottom: 16px !important;
}

.p-l-l {
  padding-left: 24px !important;
}

.p-r-l {
  padding-right: 24px !important;
}

.p-t-l {
  padding-top: 24px !important;
}

.p-b-l {
  padding-bottom: 24px !important;
}

.p-l-x {
  padding-left: 36px !important;
}

.p-r-x {
  padding-right: 36px !important;
}

.p-t-x {
  padding-top: 36px !important;
}

.p-b-x {
  padding-bottom: 36px !important;
}

/**
 * MARGIN
 */
.m-a-n {
  margin: 0 !important;
}

.m-a-t {
  margin: 4px !important;
}

.m-a-s {
  margin: 8px !important;
}

.m-a-m {
  margin: 16px !important;
}

.m-a-l {
  margin: 24px !important;
}

.m-a-x {
  margin: 36px !important;
}

.m-h-n {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-h-t {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.m-h-s {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-h-m {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-h-l {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-h-x {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.m-v-n {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-v-t {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-v-s {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-v-m {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-v-l {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-v-x {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.m-l-n {
  margin-left: 0 !important;
}

.m-r-n {
  margin-right: 0 !important;
}

.m-t-n {
  margin-top: 0 !important;
}

.m-b-n {
  margin-bottom: 0 !important;
}

.m-l-t {
  margin-left: 4px !important;
}

.m-r-t {
  margin-right: 4px !important;
}

.m-t-t {
  margin-top: 4px !important;
}

.m-b-t {
  margin-bottom: 4px !important;
}

.m-l-s {
  margin-left: 8px !important;
}

.m-r-s {
  margin-right: 8px !important;
}

.m-t-s {
  margin-top: 8px !important;
}

.m-b-s {
  margin-bottom: 8px !important;
}

.m-l-m {
  margin-left: 16px !important;
}

.m-r-m {
  margin-right: 16px !important;
}

.m-t-m {
  margin-top: 16px !important;
}

.m-b-m {
  margin-bottom: 16px !important;
}

.m-l-l {
  margin-left: 24px !important;
}

.m-r-l {
  margin-right: 24px !important;
}

.m-t-l {
  margin-top: 24px !important;
}

.m-b-l {
  margin-bottom: 24px !important;
}

.m-l-x {
  margin-left: 36px !important;
}

.m-r-x {
  margin-right: 36px !important;
}

.m-t-x {
  margin-top: 36px !important;
}

.m-b-x {
  margin-bottom: 36px !important;
}

.overlay {
  width: 1100px;
  max-width: 1100px;
  max-height: 90vh;
  z-index: 100000;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  background: #ffffff;
  border-radius: 8px;
}

.overlay-full-height {
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  z-index: 100000;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  background: #ffffff;
}

.overlay-small {
  position: relative;
  z-index: 100000;
  background: #ffffff;
  padding: 50px;
  width: 550px;
  max-width: 900px;
}

.overlay-minimal {
  position: relative;
  z-index: 100000;
  background: #ffffff;
  padding: 50px;
  width: 100%;
  max-width: 900px;
}

.overlay-functional {
  position: relative;
  z-index: 100000;
  background: #ffffff;
  padding: 50px;
  min-width: 500px;
  max-width: 100vw;
}

.overlay-nilm {
  position: relative;
  z-index: 100000;
  background: #ffffff;
  width: 700px;
  max-width: 100vw;
  overflow: hidden;
}

.overlay > .overlay-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.overlay .overlay-container .header {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.overlay .overlay-container .content {
  display: flex;
  flex-direction: column;
}

.overlay .detail-overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dialog-overlay {
  min-width: 10vw;
  min-height: 10vh;
  max-height: 70vh;
  z-index: 10000;
  position: relative;
  border-radius: 10px;
  background: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/**
 * Overrides
 */
.cdk-overlay-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.popover-backdrop {
  background: rgba(70, 65, 61, 0.6);
  scroll-behavior: unset;
}

.close-overlay-control {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/**
 * PADDING
 */
.p-a-n {
  padding: 0;
}

.p-a-t {
  padding: 4px !important;
}

.p-a-s {
  padding: 8px !important;
}

.p-a-m {
  padding: 16px !important;
}

.p-a-l {
  padding: 24px !important;
}

.p-a-x {
  padding: 36px !important;
}

.p-h-n {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-h-t {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-h-s {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-h-m {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-h-l {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-h-x {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.p-v-n {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-v-t {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-v-s {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-v-m {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-v-l {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-v-x {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.p-l-n {
  paddin-left: 0 !important;
}

.p-r-n {
  paddin-right: 0 !important;
}

.p-t-n {
  paddin-top: 0 !important;
}

.p-b-n {
  paddin-bottom: 0 !important;
}

.p-l-t {
  padding-left: 4px !important;
}

.p-r-t {
  padding-right: 4px !important;
}

.p-t-t {
  padding-top: 4px !important;
}

.p-b-t {
  padding-bottom: 4px !important;
}

.p-l-s {
  padding-left: 8px !important;
}

.p-r-s {
  padding-right: 8px !important;
}

.p-t-s {
  padding-top: 8px !important;
}

.p-b-s {
  padding-bottom: 8px !important;
}

.p-l-m {
  padding-left: 16px !important;
}

.p-r-m {
  padding-right: 16px !important;
}

.p-t-m {
  padding-top: 16px !important;
}

.p-b-m {
  padding-bottom: 16px !important;
}

.p-l-l {
  padding-left: 24px !important;
}

.p-r-l {
  padding-right: 24px !important;
}

.p-t-l {
  padding-top: 24px !important;
}

.p-b-l {
  padding-bottom: 24px !important;
}

.p-l-x {
  padding-left: 36px !important;
}

.p-r-x {
  padding-right: 36px !important;
}

.p-t-x {
  padding-top: 36px !important;
}

.p-b-x {
  padding-bottom: 36px !important;
}

/**
 * MARGIN
 */
.m-a-n {
  margin: 0 !important;
}

.m-a-t {
  margin: 4px !important;
}

.m-a-s {
  margin: 8px !important;
}

.m-a-m {
  margin: 16px !important;
}

.m-a-l {
  margin: 24px !important;
}

.m-a-x {
  margin: 36px !important;
}

.m-h-n {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-h-t {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.m-h-s {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-h-m {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-h-l {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-h-x {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.m-v-n {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-v-t {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-v-s {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-v-m {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-v-l {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-v-x {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.m-l-n {
  margin-left: 0 !important;
}

.m-r-n {
  margin-right: 0 !important;
}

.m-t-n {
  margin-top: 0 !important;
}

.m-b-n {
  margin-bottom: 0 !important;
}

.m-l-t {
  margin-left: 4px !important;
}

.m-r-t {
  margin-right: 4px !important;
}

.m-t-t {
  margin-top: 4px !important;
}

.m-b-t {
  margin-bottom: 4px !important;
}

.m-l-s {
  margin-left: 8px !important;
}

.m-r-s {
  margin-right: 8px !important;
}

.m-t-s {
  margin-top: 8px !important;
}

.m-b-s {
  margin-bottom: 8px !important;
}

.m-l-m {
  margin-left: 16px !important;
}

.m-r-m {
  margin-right: 16px !important;
}

.m-t-m {
  margin-top: 16px !important;
}

.m-b-m {
  margin-bottom: 16px !important;
}

.m-l-l {
  margin-left: 24px !important;
}

.m-r-l {
  margin-right: 24px !important;
}

.m-t-l {
  margin-top: 24px !important;
}

.m-b-l {
  margin-bottom: 24px !important;
}

.m-l-x {
  margin-left: 36px !important;
}

.m-r-x {
  margin-right: 36px !important;
}

.m-t-x {
  margin-top: 36px !important;
}

.m-b-x {
  margin-bottom: 36px !important;
}

/**
 * layout
 */
.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.inflate {
  width: 100% !important;
  height: 100% !important;
}

.center-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-contents-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.right-align-contents {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.-row-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.col-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.frow {
  display: flex;
  flex-direction: row;
}

.splitrow {
  display: flex;
  flex-direction: row;
}
.splitrow > * {
  width: 50%;
}
.splitrow > *:nth-child(odd) {
  margin-right: 7.5px;
}
.splitrow > *:nth-child(even) {
  margin-left: 7.5px;
}

.scroll-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

/**
 * GENERAL
 */
.grow-space {
  flex-grow: 1 !important;
}

.inline-el {
  display: inline-block;
}

/**
 * MIXINS
 */
/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

h1.eon-headline {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 32px;
  color: #39393a;
}

.button-wla {
  height: 55px;
  display: block;
  background: #F59B00;
  color: #fff;
  margin: 50px auto 20px;
  padding: 13px 10px;
  font-size: 22px;
  text-decoration: none;
  text-align: center;
  /*line-height: 55px; */
  border: 0 !important;
  border-radius: 27px;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
}
.button-wla.reg {
  width: 240px;
}

.override-btn {
  margin: 0;
  margin-bottom: 15px;
  min-width: 360px;
}

.override-btn2 {
  margin: 0;
  margin-bottom: 15px;
  min-width: 240px;
}

.btn-scale {
  height: 55px;
  width: 100%;
  display: block;
  background: #F59B00;
  color: #fff;
  padding: 13px 10px;
  font-size: 22px;
  text-decoration: none;
  text-align: center;
  /*line-height: 55px; */
  border: 0 !important;
  border-radius: 27px;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
}

.btn-round-edit {
  width: 45px;
  height: 45px;
  border: 0;
  border-radius: calc(45px / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
}
.btn-round-edit .btn-edit-icon {
  width: 100%;
  height: 100%;
  background-image: url(/assets/images/ui-icons/edit/editTransparent@3x.png);
  background-size: contain;
}

.button-reset {
  border: none;
  background: 0;
}
.button-reset:hover {
  outline: none;
}

/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

.text-input {
  height: 55px;
  width: 100%;
  padding: 0 20px;
  margin: 0;
  background: transparent;
  font-family: "Innogy light", sans-serif;
  font-size: 22px;
  font-weight: lighter;
  color: #333333;
  border: 2px solid #D9D9D9;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
  border-radius: 27px;
}

/**
 * EON DROPDOWN MIMIC
 */
.eon-dropdown {
  box-sizing: border-box;
  position: relative;
  border: 1px solid #8f9192;
  border-radius: 3px;
  height: 42px;
  min-width: 80px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}
.eon-dropdown > label {
  width: 0;
  height: 0;
  visibility: hidden;
}
.eon-dropdown > select {
  width: 100%;
  height: 100%;
  background-color: white;
  padding-left: 12px;
  padding-right: 42px;
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 18px;
  cursor: pointer;
}
.eon-dropdown > span {
  position: absolute;
  right: calc(1px);
  top: calc(1px);
  background-color: #f6f6f7;
  pointer-events: none;
  background-image: url("/assets/img/icons/chevron-down/chevron-down.svg");
  background-size: 16px 10px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: calc(100% - 2px);
  width: 38px;
}

.eon-dropdown:hover {
  transition: border-color 200ms ease;
  border-color: #39393a;
}
.card {
  width: 100%;
  height: 100%;
  padding: 25px 30px;
  background: #FFFFFF;
}
.card h3 {
  font-size: 30px;
  font-family: "Innogy regular", sans-serif;
  font-weight: normal;
}
.card p {
  line-height: 24pt;
}

@media screen and (max-width: 1400px) {
  .card {
    padding: 35px 40px;
  }
}
/**
 * boeppel
 */
.boeppel {
  position: absolute;
  top: -6px;
  right: -4px;
  width: 18px;
  height: 18px;
  border: 3px solid white;
  border-radius: 10px;
  background: #ffc300;
  z-index: 1000;
}

.boeppel-big {
  position: absolute;
  top: 21px;
  right: 52px;
  width: 25px;
  height: 25px;
  border: 1px solid #ffc300;
  border-radius: 50%;
  background: #ffc300;
}

.boeppel-list {
  position: absolute;
  top: -6px;
  right: 8px;
  width: 16px;
  height: 16px;
  border: 2px solid white;
  border-radius: 10px;
  background: #ffc300;
}

/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

/**
 * MENU
 */
.-block-menu {
  padding: 40px 40px 40px;
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: white;
}

.-menu-entries {
  margin-bottom: 20px;
}

.-block-menu ul {
  list-style-type: none;
}

.-block-menu li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.-block-menu li a {
  text-decoration: none;
}

.-block-menu li a.active {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: none;
}

.-logo {
  height: 25px;
  margin-bottom: 30px;
  background-image: url("/assets/img/logos/eon-logo-white.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

.tile-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 30px;
  position: relative;
}

.tile-view > .tile-wrapper {
  width: 33.3%;
}

.tile-container {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.tile {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 8px;
  background: #FFFFFF;
  cursor: pointer;
}

.tile-add {
  cursor: pointer;
}

.tile-close-control {
  display: inline-block;
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 10000;
}

.tile-inner {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tile-header-row {
  display: flex;
  flex-direction: row;
}

.tile-header-row > h3 {
  flex: 1;
}

h3.tile-heading {
  font-size: 32px;
  color: #39393a;
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.tile-heading {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.tile-heading > h3 {
  flex: 1 0;
  font-size: 32px;
  color: #39393a;
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-bottom: 0;
}

.tile-close-control {
  position: absolute;
  top: 20px;
  right: 20px;
}

.tile-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.tile-chart-abs {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

.detail-view {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

section.detail-head {
  background: #f6f6f7;
  padding: 50px;
}

.detail-head > header {
  display: flex;
  flex-direction: row;
}
.detail-head > header h1 {
  margin: 0;
  flex: 1 0 auto;
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 40px;
}
.detail-head > header button {
  border: none;
  background: transparent;
  padding-left: 20px;
}

.detail-head > section {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

section.detail-info {
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 50px;
  background: #f8f7bf;
  display: flex;
  flex-direction: row;
}

.detail-info > .info-icon {
  margin-right: 30px;
}

.detail-info > .info-text p {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 18px;
}

section.detail-content {
  padding: 30px 50px 50px;
}

/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

.page {
  padding: 128px 75px 75px;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.page-card {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 50px;
}

.page-card > section {
  margin-bottom: 50px;
}

.page-card h1 {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 40px;
}

.page-card h2 {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 32px;
}

.page-card h2 .extra-space {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 32px;
  margin-bottom: 40px;
}

.page-card h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 24px;
}

.page-card ul {
  margin-bottom: 40px;
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 24px;
}

.row > div:first-child {
  margin-right: 20px;
}

/**
 * IMPRINT SPECIFIC
 */
section .imprint {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
section .imprint h2 {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.tab-selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.tab-selector > div {
  width: 50%;
  cursor: pointer;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 2px solid #8ed0d8;
  text-align: center;
  color: #8ed0d8;
}
.tab-selector .active {
  color: white;
  border-bottom: 2px solid white;
}

fieldset.eon-input {
  position: relative;
  height: 56px;
  border: 2px solid #8F9192;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 10px;
}

fieldset.eon-input:focus-within {
  border-color: #519fb1;
}

fieldset.eon-input > legend {
  font-size: 14px;
  padding-left: 3px;
  padding-right: 3px;
  font-family: EONBrixSans, sans-serif;
  color: #39393a;
}

.eon-input > input {
  position: absolute;
  top: 7px;
  width: calc(100% - 20px);
  border: none;
  font-size: 18px;
  font-family: EONBrixSans, sans-serif;
  color: #39393a;
}

.eon-input > i {
  position: absolute;
  right: 10px;
  top: 3px;
  cursor: pointer;
}

/**
 * DEFINITIONS
 */
/**
 * CLASSES
 */
.yellow {
  color: #ffc300;
}

.indigo-clr {
  color: #00aae1;
}

.indigo-dark-clr {
  color: #143c8c;
}

.yellow-bg {
  background: #ffc300;
}

.white-svg svg {
  width: 150px;
  height: 150px;
}
.white-svg svg path {
  fill: #FFFFFF;
}
@media screen and (max-width: 480px) {
  .white-svg svg {
    width: 100px;
    height: 100px;
  }
}

/**
 * E.ON
 */
.turquoise {
  background: #1ea2b1 !important;
}

i.feedin {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/sun.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/sun.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #39393a;
}

i.consumption {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/thunder.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/thunder.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #1ea2b1;
}

i.consumption-alert {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/thunder.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/thunder.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #b00402;
}

i.calendar {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  mask-image: url("~src/assets/img/icons/calendar.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/calendar.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #828282;
}

/**
 * password visibility icon
 */
i.password-hidden {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  background-image: url("~src/assets/img/icons/eye.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

i.password-visible {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  background-image: url("~src/assets/img/icons/eye-crossed.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

i.info-s {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  mask-image: url("~src/assets/img/icons/info.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/info.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.close-s {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  mask-image: url("~src/assets/img/icons/close.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/close.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.wifi-s {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  mask-image: url("~src/assets/img/icons/wifi.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/wifi.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.alert-s {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  mask-image: url("~src/assets/img/icons/alert.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/alert.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.plus-s {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  mask-image: url("~src/assets/img/icons/plus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/plus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.minus-s {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  mask-image: url("~src/assets/img/icons/minus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/minus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.info-sm {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  mask-image: url("~src/assets/img/icons/info.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/info.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.close-sm {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  mask-image: url("~src/assets/img/icons/close.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/close.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.wifi-sm {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  mask-image: url("~src/assets/img/icons/wifi.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/wifi.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.alert-sm {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  mask-image: url("~src/assets/img/icons/alert.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/alert.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.plus-sm {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  mask-image: url("~src/assets/img/icons/plus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/plus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.minus-sm {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  mask-image: url("~src/assets/img/icons/minus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/minus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.info-m {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/info.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/info.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.close-m {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/close.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/close.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.wifi-m {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/wifi.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/wifi.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.alert-m {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/alert.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/alert.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.plus-m {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/plus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/plus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.minus-m {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  mask-image: url("~src/assets/img/icons/minus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/minus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.info-l {
  display: inline-block;
  width: 45px !important;
  height: 45px !important;
  mask-image: url("~src/assets/img/icons/info.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/info.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.close-l {
  display: inline-block;
  width: 45px !important;
  height: 45px !important;
  mask-image: url("~src/assets/img/icons/close.webp");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/close.webp");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.wifi-l {
  display: inline-block;
  width: 45px !important;
  height: 45px !important;
  mask-image: url("~src/assets/img/icons/wifi.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/wifi.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.alert-l {
  display: inline-block;
  width: 45px !important;
  height: 45px !important;
  mask-image: url("~src/assets/img/icons/alert.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/alert.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.plus-l {
  display: inline-block;
  width: 45px !important;
  height: 45px !important;
  mask-image: url("~src/assets/img/icons/plus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/plus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.minus-l {
  display: inline-block;
  width: 45px !important;
  height: 45px !important;
  mask-image: url("~src/assets/img/icons/minus.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url("~src/assets/img/icons/minus.svg");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

i.dark-grey {
  background-color: #39393a;
}

i.turquoise {
  background-color: #1ea2b1;
}

i.bordeaux {
  background-color: #b00402;
}

i.white {
  background-color: #ffffff;
}

/**
 * layout
 */
.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.inflate {
  width: 100% !important;
  height: 100% !important;
}

.center-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-contents-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.right-align-contents {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.-row-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.col-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.frow {
  display: flex;
  flex-direction: row;
}

.splitrow {
  display: flex;
  flex-direction: row;
}
.splitrow > * {
  width: 50%;
}
.splitrow > *:nth-child(odd) {
  margin-right: 7.5px;
}
.splitrow > *:nth-child(even) {
  margin-left: 7.5px;
}

.scroll-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

/**
 * GENERAL
 */
.grow-space {
  flex-grow: 1 !important;
}

.inline-el {
  display: inline-block;
}

/**
 * MIXINS
 */
button.eon-button-new {
  position: relative;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 18px;
  color: white;
  border: 0;
  cursor: pointer;
  transition: background-color 100ms ease;
  background-color: #ea1b0a;
}
button.eon-button-new:before {
  display: block;
  position: absolute;
  top: 0;
  left: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/left.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #ea1b0a;
}
button.eon-button-new:after {
  display: block;
  position: absolute;
  top: 0;
  right: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/right.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #ea1b0a;
}
button.eon-button-new:hover {
  background-color: #b9280a;
}
button.eon-button-new:hover:before {
  background-color: #b9280a;
}
button.eon-button-new:hover:after {
  background-color: #b9280a;
}

button.eon-icon-button {
  position: relative;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 18px;
  color: white;
  border: 0;
  cursor: pointer;
  transition: background-color 100ms ease;
  background-color: #ea1b0a;
  padding-left: 0;
  padding-right: 0;
}
button.eon-icon-button:before {
  display: block;
  position: absolute;
  top: 0;
  left: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/left.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #ea1b0a;
}
button.eon-icon-button:after {
  display: block;
  position: absolute;
  top: 0;
  right: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/right.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #ea1b0a;
}
button.eon-icon-button:hover {
  background-color: #b9280a;
}
button.eon-icon-button:hover:before {
  background-color: #b9280a;
}
button.eon-icon-button:hover:after {
  background-color: #b9280a;
}

button.eon-button-new.turquoise {
  transition: background-color 100ms ease;
  background-color: #1ea2b1;
}
button.eon-button-new.turquoise:before {
  display: block;
  position: absolute;
  top: 0;
  left: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/left.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #1ea2b1;
}
button.eon-button-new.turquoise:after {
  display: block;
  position: absolute;
  top: 0;
  right: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/right.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #1ea2b1;
}
button.eon-button-new.turquoise:hover {
  background-color: #2b91a3;
}
button.eon-button-new.turquoise:hover:before {
  background-color: #2b91a3;
}
button.eon-button-new.turquoise:hover:after {
  background-color: #2b91a3;
}

a.eon-button-new {
  position: relative;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 18px;
  color: white;
  border: 0;
  cursor: pointer;
  transition: background-color 100ms ease;
  background-color: #ea1b0a;
  text-decoration: none;
  color: white;
}
a.eon-button-new:before {
  display: block;
  position: absolute;
  top: 0;
  left: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/left.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #ea1b0a;
}
a.eon-button-new:after {
  display: block;
  position: absolute;
  top: 0;
  right: -24px;
  height: 48px;
  width: 24px;
  mask-image: url("~src/assets/img/shapes/eon-button-shapes/right.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 100ms ease;
  content: " ";
  background-color: #ea1b0a;
}
a.eon-button-new:hover {
  background-color: #b9280a;
}
a.eon-button-new:hover:before {
  background-color: #b9280a;
}
a.eon-button-new:hover:after {
  background-color: #b9280a;
}

a.button:visited {
  color: white;
}

button.eon-button-new.small {
  height: 34px;
  padding-left: 17px;
  padding-right: 17px;
  margin-left: 17px;
  margin-right: 17px;
}

button.eon-button-new.small:before {
  left: -17px;
  height: 34px;
  width: 17px;
}

button.eon-button-new.small:after {
  right: -17px;
  height: 34px;
  width: 17px;
}

button.icon-only {
  width: 32px;
  height: 32px;
  mask-image: url("~src/assets/img/graphics/button-shape/button-shape.svg ");
  mask-size: contain;
  mask-position: center;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

button.icon-only.turqouise {
  background-color: #1ea2b1;
  transition: background-color 100ms ease;
}
button.icon-only.turqouise:hover {
  background-color: #2b91a3;
}

/**
 * regular
 */
.eon-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.font-regular {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

/**
 * regular italic
 */
.eon-regularit {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-regular-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BOLD
 */
.eon-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-bold {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/**
 * BOLD ITALIC
 */
.eon-bold-it {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

.font-boldit {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
}

/**
 * BLACK
 */
.eon-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.font-black {
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

/*
 * TAG OVERRIDES
 */
strong {
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

p.subtext {
  font-family: EONBrixSans, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  font-size: 14px;
  margin-left: 60px;
  padding-right: 20px;
}

/**
 * LIVE
 */
.column-callout {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 6px;
  background: white;
  border-top: 2px solid #3295A5;
  border-radius: 2px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.column-callout > .header {
  padding-top: 6px;
  background: white;
  text-align: start;
  font-family: EONBrixSans, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 12px;
  color: black;
}
.column-callout > .body {
  background: white;
  text-align: center;
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 16px;
  color: black;
}

/**
 * CONSUMPTION ALERT
 */
.consumption-alert-callout {
  padding: 5px;
  padding-right: 15px;
  background: #b00402;
  color: white;
  font-family: EONBrixSans, sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.consumption-alert-callout.positive {
  background: #0097A7;
}
.consumption-alert-callout.negative {
  background: #b00402;
}

/**
 * APPLIANCE
 */
.appliance-callout {
  pointer-events: none;
}
.appliance-callout .appliance-callout-icon {
  position: relative;
  width: 48px;
  height: 48px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iic {
  position: relative !important;
}

/**
 * Button
 */
.iona-button {
  display: inline-block;
  background: transparent;
  width: 240px;
  padding: 13px 10px;
  text-decoration: none;
  font-size: 21px;
  text-align: center;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  border: 2px solid white;
  color: white;
}
.iona-button:hover {
  color: #ffc300;
}

/**
 * general
 */
.toast-container .ngx-toastr {
  box-shadow: none !important;
}

.pointer {
  cursor: pointer;
}

/**
 * SVG ICON BRANCHES
 */
.household-icon-active {
  -webkit-transition: fill 250ms ease;
  -moz-transition: fill 250ms ease;
  -ms-transition: fill 250ms ease;
  -o-transition: fill 250ms ease;
  transition: fill 250ms ease;
}
.household-icon-active svg .fill-active {
  fill: #1ea2b1;
}
.household-icon-active svg .stroke-active {
  stroke: #39393a;
}