@import "../fonts";

/**
 * MENU
 */
.-block-menu {
    padding: 40px 40px 40px;
    @include eon-regular;
    color: white;
}

.-menu-entries {
    margin-bottom: 20px;
}

.-block-menu ul {
    list-style-type: none;
}

.-block-menu li {
    margin-top: 10px;
    margin-bottom: 10px;
}

.-block-menu li a {
    text-decoration: none;
}

.-block-menu li a.active {
    @include eon-bold;
    text-decoration: none;
}

.-logo {
    height: 25px;
    margin-bottom: 30px;
    background-image: url("/assets/img/logos/eon-logo-white.png");
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
