@import "~@eon-ui/eon-ui-font-brixsans/src/brixsans.css";

/**
 * regular
 */
@mixin eon-regular() {
    font-family: EONBrixSans, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
}

.eon-regular {
    @include eon-regular;
}

.font-regular {
    @include eon-regular;
}

/**
 * regular italic
 */
@mixin eon-regular-italic() {
    font-family: EONBrixSans, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
}

.eon-regularit {
    @include eon-regular-italic;
}

.font-regular-it {
    @include eon-regular-italic;
}

/**
 * BOLD
 */
@mixin eon-bold() {
    font-family: EONBrixSans, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.eon-bold {
    @include eon-bold;
}

.font-bold {
    @include eon-bold;
}

/**
 * BOLD ITALIC
 */
@mixin eon-bold-italic() {
    font-family: EONBrixSans, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
}

.eon-bold-it {
    @include eon-bold-italic;
}

.font-boldit {
    @include eon-bold-italic;
}

/**
 * BLACK
 */
@mixin eon-black() {
    font-family: EONBrixSans, sans-serif;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

}

.eon-black {
    @include eon-black;
}

.font-black {
    @include eon-black;
}

/*
 * TAG OVERRIDES
 */
strong {
    @include eon-bold;
}

p.subtext {
    @include eon-regular;
    font-size: 14px;
    margin-left: 60px;
    padding-right: 20px;
}

