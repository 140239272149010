@mixin iona-icon-base() {
    font-family: "Innogy Icons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 25px;

    color: #F59B00;

    &:hover {
        color: #333333;
    }
}

@mixin iona-icon-button() {
    border: 0;
    background: 0;
    &:focus {
        border: 0;
        background: 0;
        outline: 0;
    }
}

.icn-btn-close {
    @include iona-icon-base;
    @include iona-icon-button;

    &:before {

        content: "\6a";
    }
}
